import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import ButtonRed from '../ButtonRed'
import TrustRoll from "../../contracts/TrustRoll.json";
import Web3 from "web3";
import QRCodeModal from "@walletconnect/qrcode-modal";
import ModalWindowConnectWallet from './ModalWindowConnectWallet'
import WalletConnectProvider from "@walletconnect/web3-provider";
import HttpConnection from "@walletconnect/http-connection";
import constants from "../../constance";
// import { keyframes } from "styled-components";
import BlockchainContext from "../../BlockchainContext.js";
import ButtonTransparent from '../header/ButtonTransparent';
import { useTranslation, Trans } from 'react-i18next';
// import {transpileModule} from "typescript";
// import {resolve} from "react-preloaders/webpack.config";
// import constants from "./constance";
// const {
//   NET
// } = Net;
// console.log(NET)
const { NET } = constants;

const CONTRACT_ADDRESS = NET.BLOCKCHAIN.CONTRACT_ADDRESS;
const PORT = NET.SERVER_API_URL;
const CHAIN_ID = NET.BLOCKCHAIN.CHAIN_ID;
const NETWORK_NAME = NET.BLOCKCHAIN.NETWORK_NAME;
const BASE_SCAN_URL = NET.BASE_SCAN_URL;
const RPC_URL = NET.BLOCKCHAIN.RPC_URL;
const NETWORKS = NET.BLOCKCHAIN[0];


export default function ConnectWallet({ position, ...props }) {
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation(); // multilang

  const blockchainContext = useContext(BlockchainContext)
  const { setBalance, web3AndContractCredentials, setWeb3AndContractCredentials, walletConnectedFlag, setWalletConnectedFlag, buttonPlay, setButtonPlay, setIncorrectNetwork, } = blockchainContext

  const {
    web3,
    accounts,
    contract,
    lockedInBets,
    contractBalance,
    gamblerBalance,
    maxProfit,
    jackpotSize,
  } = web3AndContractCredentials
  // console.log(web3AndContractCredentials)
  // console.log(web3AndContractCredentials.owner)
  // console.log(web3AndContractCredentials.accounts)
  // console.log(web3AndContractCredentials.web3)
  // console.log(web3AndContractCredentials.contractBalance)
  // console.log(web3AndContractCredentials.maxProfit)
  // console.log(web3AndContractCredentials.jackpot)
  // console.log(typeof(web3AndContractCredentials))
  let details = {}
  // console.log(window.ethereum)

  const changeNetwork = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: ('0x' + CHAIN_ID.toString(16)) }],
      });
      //   setButtonPlay(true); //to stop loader
      //   setWalletConnectedFlag(false);
      setIncorrectNetwork(false);
      connectingToWallet('metaMask');
    } catch (switchError) {
      // chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          let ress = await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{ ...NETWORKS, }],
          })
          setIncorrectNetwork(false);
          connectingToWallet('metaMask');
        } catch (addError) {
          //pass
        }
      }//if user revert change network
      if (switchError.code === 4001) {
        //pass
      }
    } finally {
      //pass

    }

  };
  const handleNetworkSwitch = async (flag) => {
    //TODO: окошко с просьбой выбрать правильную сеть
    // console.log('handleNetworkSwitch')
    
    if (!window.ethereum) {
      await connectingToWallet();
    } else {
      if (window.screen.width < 1024) {

        connectingToWallet();
      } else {
        if (
          walletConnectedFlag === false &&
          Web3.givenProvider.chainId === ('0x' + (CHAIN_ID.toString(16)))
        ) {
          // console.log('maaain')
          await connectingToWallet('metaMask');
        } else if (walletConnectedFlag === true) {
          return // when wallet is connect we can`t click button 'connect wallet' and it is normal state 
        }
        else {
          // console.log('else')
          await changeNetwork();
        }

      }
    }
  };

  const networkChanged = () => {
    // console.log('0x' + (CHAIN_ID.toString(16)));
    console.log(Web3.givenProvider.chainId);
    if (
      walletConnectedFlag === false &&
      Web3.givenProvider.chainId === ('0x' + (CHAIN_ID.toString(16)))
    ) {
      console.log(Web3.givenProvider.chainId);
      setWalletConnectedFlag(false);
      setIncorrectNetwork(false);
    } else {
      console.log(Web3.givenProvider.chainId);
      //   window.ethereum.removeListener("chainChanged", networkChanged);
      //   window.ethereum.removeListener("accountsChanged", accountsChange);
      setIncorrectNetwork(true);
      setWalletConnectedFlag(false);
    }
  };

  function accountsChange() {
    console.log(Web3.givenProvider.chainId);
    if (
      walletConnectedFlag === false &&
      Web3.givenProvider.chainId === ('0x' + (CHAIN_ID.toString(16)))
    ) {
      console.log(Web3.givenProvider.chainId);
      setWalletConnectedFlag(false);
      const web3 = new Web3(window.ethereum);
      const swapCurrentAccount = async () => {
        let accounts = await web3.eth.getAccounts();
        let contract = await new web3.eth.Contract(TrustRoll.abi, CONTRACT_ADDRESS);
        getContractData({web3, accounts, contract})
        return accounts;
      };
      swapCurrentAccount()
      // setIncorrectNetwork(false);
    } else {
      console.log(Web3.givenProvider.chainId);
      console.log("accountsChange: ", "ERROR");
      window.ethereum.removeListener("chainChanged", networkChanged);
      window.ethereum.removeListener("accountsChanged", accountsChange);
      setIncorrectNetwork(true);
      setWalletConnectedFlag(false);
    }
  }
  // function ethAction(e) {
  //   console.log(e)
  // }
  // useEffect(() => {
  function addListeners() {
    try {
      window.ethereum.on("chainChanged", networkChanged);
      window.ethereum.on("accountsChanged", accountsChange);
      // window.ethereum.on("accountsChange", ethAction("accountsChange"));
      // window.ethereum.on("networkChanged", ethAction("networkChanged"));
      // window.ethereum.on("bound _handleDisconnect", ethAction("bound _handleDisconnect"));
      // window.ethereum.on("accountsChange", ethAction(e));
      window.ethereum.on("disconnect", () => {
        setWalletConnectedFlag(false);
        setButtonPlay(false);
        //   window.ethereum.removeListener("chainChanged", networkChanged);
        //   window.ethereum.removeListener("accountsChanged", accountsChange);
      });
      // return () => {
      //   window.ethereum.removeListener("chainChanged", networkChanged);
      //   window.ethereum.removeListener("accountsChanged", accountsChange);
      // };
      // handleNetworkSwitch();
    } catch (e) {
      console.log(e)
      //pass
    }

  }

  // }
  // }, []);

  async function connectingByWalletConnect() {
    try {
      await killSessionWeb3();
    } catch (error) {
      // console.log(error);
      setButtonPlay(false); //to stop loader
    }

    const bridge = "https://bridge.walletconnect.org";
    let provider3;
    provider3 = new WalletConnectProvider({
      bridge: bridge,
      // infuraId: "010964d8091b43fa8c3498b1e9e9779b",
      rpc: {
        [CHAIN_ID]: RPC_URL,
      },
      qrcodeModalOptions: {
        qrcodeModal: QRCodeModal,
        // http: HttpConnection,
        mobileLinks: [
          "rainbow",
          "metamask",
          "argent",
          "trust",
          "imtoken",
          "pillar",
        ],
        desktopLinks: [
          "metamask",
          "walletconnect"

        ]
      },
    });

    // console.log("provider3: ", provider3)
    let importantInformations = `MetaMask: 'ethereum.enable()' is deprecated and may be removed in the future. Please use the 'eth_requestAccounts' RPC method instead. For more information, see: https://eips.ethereum.org/EIPS/eip-1102`;

    await provider3.enable();
    const web33 = new Web3(provider3);
    // console.log("web33: ", web33);
    return web33;
  }
  async function connectingByMetaMask() {
    if (window.ethereum) {
      // try {
        const web3 = new Web3(window.ethereum);
        // console.log('error')
        await window.ethereum.enable();
        // console.log('error')
        addListeners()
        // console.log('error')
        return web3;

        //
      // } catch (error) {
      //   // let web33 = connectingByWalletConnect();
      //   // return web33;
        // console.log(error)
      // setButtonPlay(false);
      // // break;
      // return ;
      //
      // }
    } else if (window.web3) {
      let web3 = window.web3;
      // console.log("Injected web3 detected.");
      return web3;
    } else {
      // console.log("No web3 instance injected, using Local web3.");
      // let web33 = connectingByWalletConnect();
      // return web33;
        // console.log('else')
      // setButtonPlay(false);
      return ;
    }
  }
  async function connectingToWallet(flag, e) {
    // console.log(e);
    // e.preventDefault();
    setButtonPlay(true);
    closeModal()
    // setShowModal(true)
    try {
      let getWeb3Credatials = new Promise(async function (resolve, reject) {
        let web3 ;
        try{
          if (flag === 'metaMask'){
            web3 = await connectingByMetaMask();
          } else {
            web3 = await connectingByWalletConnect();
          }
        }catch(e){
          console.log('reject: ' , e);
          reject(e)
        }
        // console.log(web3)
        let accounts ;
        let contract;
        // console.log(buttonPlay)
        // console.log(web3)
        // if (web3.eth !== undefined){
        if (web3 !== undefined){
          // console.log(web3)
          // console.log
          accounts = await web3.eth.getAccounts();
          contract = await new web3.eth.Contract(TrustRoll.abi, CONTRACT_ADDRESS);
          details = {
            web3, accounts, contract
          }
          resolve(details)
        }

        // reject(details)
        // console.log(details)
      })

      getWeb3Credatials.then(details => {
        // console.log(details)
        const { web3, accounts, contract } = details
        if (
          typeof web3 !== "undefined" &&
          typeof accounts !== "undefined" &&
          typeof contract !== "undefined" &&
          walletConnectedFlag === false
        ) {
          // console.log(details)
          getContractData(details)
          // load(details);
        } else {
          setWalletConnectedFlag(false);
        }
      })
       
      getWeb3Credatials.catch((err) => {
        setButtonPlay(false);
      })
      getWeb3Credatials.finally(()=>{
        // console.log('finally')
        setButtonPlay(false);
        setWalletConnectedFlag(false);
      })
    } catch (error) {
      console.log(error);

      // if (switchError.code === 4902) {
      // console.error(error);
      setButtonPlay(false);
      setWalletConnectedFlag(false);
      killSessionWeb3()
    }
  }

  // const load = async () => {
  async function getContractData(details) {
    let maxProfit, lockedInBets, jackpotSize, contractBalance, gamblerBalance;

    const { web3, accounts, contract } = details

    contract.methods.maxProfit().call().then((res) => {
      maxProfit = res;
      // console.log('1', res)
      checkContractData();
    })
    contract.methods.lockedInBets().call().then((res) => {
      lockedInBets = res;
      // console.log('2', res)
      checkContractData();
    })
    contract.methods.jackpotSize().call().then((res) => {
      jackpotSize = res;
      // console.log('3',res)
      checkContractData();
    })
    web3.eth.getBalance(CONTRACT_ADDRESS).then((res) => {
      contractBalance = res;
      // console.log('4',res)
      checkContractData();
    })
    web3.eth.getBalance(accounts[0]).then((res) => {
      gamblerBalance = res;
      // console.log('5',res)
      checkContractData();
    })

    const checkContractData = () => {
      if (maxProfit && lockedInBets && jackpotSize && contractBalance && gamblerBalance) {
        // console.log('6')
        setContractData();
      }
    }

    const setContractData = () => {
      setWeb3AndContractCredentials({
        web3,
        accounts,
        contract,
        lockedInBets,
        contractBalance,
        gamblerBalance,
        maxProfit,
        jackpotSize,
      })
      setBalance(gamblerBalance)
      setButtonPlay(false); //to stop loader
      setWalletConnectedFlag(true);
    }

  }

  // const load = async (details) => {
  //   try {
  //     getContractData(details)
  // const croupier = await contract.methods.croupier().call();
  // const owner = await contract.methods.owner().call();
  // const maxProfit = await contract.methods.maxProfit().call();
  // const lockedInBets = await contract.methods.lockedInBets().call();
  // const jackpot = await contract.methods.jackpotSize().call();
  // let contractBalance = await web3.eth.getBalance(CONTRACT_ADDRESS);
  // const gamblerBalance = await web3.eth.getBalance(accounts[0]);

  // // contract.events.Payment({}).on("data", function (event) {
  // // console.log(event) //event.value какое-то
  // //   } );

  // setCroupier(croupier);
  // setOwner(owner);
  // setLockedInBets(lockedInBets);
  // setContractBalance(contractBalance);
  // setGamblerBalance(gamblerBalance);
  // setMaxProfit(maxProfit);
  // setJackpotSize(jackpot);
  // setButtonPlay(false); //to stop loader
  // setWalletConnectedFlag(true);

  // } catch (e) {
  //   setButtonPlay(false);
  //   console.log(e);
  // }
  // console.log("web3.eth");
  // };

  async function killSessionWeb3() {
    let provider3;
    provider3 = new WalletConnectProvider({
      infuraId: "010964d8091b43fa8c3498b1e9e9779b",
      rpc: {
        [CHAIN_ID]: RPC_URL,
      },
    });
    // console.log("kill");
    await provider3.disconnect();
    await provider3.close();
  }
  function openModal() {
    // If MetaMask is available, directly connect using MetaMask
    if (window.ethereum) {
      connectingToWallet('metaMask');
    } else {
      // If MetaMask is not available, show the modal for WalletConnect
      setShowModal(true);
    }
  }
  function closeModal () {
    // console.log('ff')
    setShowModal(false)
  }
  return (
    <>
      <BlockchainContext.Provider
        value={{
          ...blockchainContext,
          handleNetworkSwitch,
          closeModal,
          setShowModal,
          openModal,
          showModal,
          connectingToWallet,
        }}
      >
        {position === 'NavBar' ?

          <ButtonTransparent text={t('menu.connectwallet')} />
          // "connect wallet" />
          :
          <div className='metaMask-checker'>
            {buttonPlay ? <div className="lines l7">
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
              :
              <>
                <ButtonRed text={t('menu.connectwallet')} getWalletBtnClick={openModal} />
              </>
            }
          </div>
        }
        {/* <button onClick={openModal}>show</button> */}
        {showModal?
        <ModalWindowConnectWallet title="My Modal" onClose={closeModal} show={showModal}>
        <p>This is modal body</p>
      </ModalWindowConnectWallet>
          :<></>}
      </BlockchainContext.Provider>
    </>
  )
}