import React from "react";
import { Link } from "react-router-dom";

const Page404 = () => {
  return (
    <div className="page404">
      <p className="page404-p">Page doesn`t exist</p>
      <Link to="/" className="page404-link">
        Back to main page
      </Link>
    </div>
  );
};

export default Page404;
