class SaveGamblerChoice {
    constructor(gameCalc) {
        this.gameCalc = gameCalc
    }

    COIN_FLIP = '2'
    DICE = '6'
    TWO_DICE = '36'
    ETHEROL = '100'

    getOldChoice(betOn, choices, modulo, lastGameModulo){
        // console.log({betOn, choices, modulo, lastGameModulo})
        switch (modulo.toString()) {
            case this.COIN_FLIP:
                if(!betOn || +lastGameModulo !== +modulo ){
                    return 'head'
                } else  {
                    return choices[+betOn[0]-1]
                }

                break;

            case this.DICE:
                if(!betOn || +lastGameModulo !== +modulo) {
                    choices['1'] = true
                    return choices
                }
                betOn = betOn.map(item => {
                    return +item
                })
                betOn.forEach(item => {
                    choices[item.toString()] = true
                })
                    return choices
                break;
            case this.TWO_DICE:
                if(!betOn || +lastGameModulo !== +modulo) {
                    choices['2'] = true
                    return choices
                }
                betOn = betOn.map(item => {
                    return +item
                })
                betOn.forEach(item => {
                    choices[item.toString()] = true
                })
                return choices
                break;
            case this.ETHEROL:
                if(!betOn || +lastGameModulo !== +modulo){
                    return choices
                }
                choices = [+betOn[0]]
                break;

        }

        return choices;

    }

    getOldBetAmount(betOn, bet, modulo){
        // console.log({betOn, bet, modulo})

        if(!betOn){
            return '0.01'
        } else  {
            const winAmount = this.gameCalc.getDiceWinAmount(betOn, bet, modulo)
            const maxBet = this.gameCalc.getBetFromWinAmount(betOn, modulo, winAmount)
                if(bet>maxBet) {
                    return maxBet
            }
            return bet;
        }
    }
    getChosenDices(betOn, modulo, lastGameModulo, activeDice=1) {
        // console.log({betOn, modulo, lastGameModulo, activeDice})
        if (!betOn || +lastGameModulo !== modulo) {
            return [activeDice]
        }
        betOn = betOn.map(item => {
            return +item
        })
        return betOn
    }
}

export default SaveGamblerChoice;