import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import instagram from '../assets/img/FOOTER_instagram.svg'
// import facebook from '../assets/img/FOOTER_facebook.svg'
import youtube from '../assets/img/youtube.svg'
import email from '../assets/img/FOOTER_email.svg'
import twitter from '../assets/img/FOOTER_twitter.svg'
import discord from '../assets/img/discord.svg'
import telegram from '../assets/img/telega.svg'
import mIcon from '../assets/img/m-social.svg'
import manIcon from '../assets/img/man-social.svg'
import constants from '../constance';
import docs from '../assets/img/docs.svg'
import { useTranslation, Trans } from 'react-i18next';


export default function Footer({ setModulo, setHasPlayed, networkName, gamblerBalance, contract, baseScanUrl, balance, setConnectError }) {

    const { t } = useTranslation(); // multilang

    const [newGamblerBalance, setGamblerBalance] = useState(balance)
    const { BNB_MAINNET_BASE_LINK,
        LINK_WHITEPAPER } = constants.NET;

    useEffect(() => {
        setGamblerBalance(balance)
    }, [balance])

    return (
        <div className="footer">
            <div className='site-width'>
                <div className="footer-main">
                    <div className="footer-block">
                        <h4 className="footer-title"><Trans i18nKey="footer.ourgames">Our games</Trans></h4>
                        <Link to="/gamepage/coin_flip" onClick={() => { setModulo(2); setHasPlayed(false); setConnectError(false) }}>
                            <p><Trans i18nKey="footer.coinflip">Coin Flip</Trans></p>
                        </Link>
                        <Link to="/gamepage/dice" onClick={() => { setModulo(6); setHasPlayed(false); setConnectError(false) }}>
                            <p><Trans i18nKey="footer.dice">Dice</Trans></p>
                        </Link>
                        <Link to="/gamepage/two_dice" onClick={() => { setModulo(36); setHasPlayed(false); setConnectError(false) }}>
                            <p><Trans i18nKey="footer.twodice">Two dice</Trans></p>
                        </Link>
                        <Link to="/gamepage/trustroll" onClick={() => { setModulo(100); setHasPlayed(false); setConnectError(false) }}>
                            <p><Trans i18nKey="footer.trustroll">Trustroll</Trans></p>
                        </Link>
                        <Link to="/gamepage/roulette" onClick={() => { setModulo(37); setHasPlayed(false); }}>
                            <p><Trans i18nKey="footer.roulette">Roulette</Trans></p>
                        </Link>
                    </div>
                    <div className="footer-block">
                        <h4 className="footer-title"><Trans i18nKey="footer.blockchainfriends">Blockchain & Friends</Trans></h4>
                        <p><Trans i18nKey="footer.yourbalance">Your balance</Trans>: <span>{
                            newGamblerBalance !== undefined ?
                                (`${((Number(newGamblerBalance)) / (10 ** 18)).toFixed(3)} BNB`) :
                                <Trans i18nKey="footer.walletisnoconnected">wallet is not connected</Trans>}
                        </span>
                        </p>
                        <p><Trans i18nKey="footer.network">Network</Trans>: <span> {networkName} </span> </p>
                        <a href={baseScanUrl + `address/` + contract + `#code`} target="_blank"><Trans i18nKey="footer.smartcontract">Smart contract</Trans></a>
                        {/* <a href="https://testnet.binance.org/faucet-smart" target="_blank"><Trans i18nKey="footer.free_bnb_testnet">Free BNB testnet</Trans></a> */}
                    </div>
                    <div className="footer-block">
                        <h4 className="footer-title"><Trans i18nKey="footer.featured_on">Featured on</Trans></h4>
                        {/* <a href={LINK_WHITEPAPER} target="_blank">How TrustRoll Generate Random Numbers</a> */}
                        <a href="https://www.bitdegree.org/crypto-tracker/top-bsc-dapps/trustroll" target="_blank">BitDegree</a>
                        <a href="https://dappradar.com/dapp/trustroll" target="_blank">DappRadar</a>
                        {/* <a href="https://www.dapp.com/app/trust-roll" target="_blank">Dapp</a> */}
                    </div>
                    <div className="footer-social footer-block">
                        <h4 className="footer-title"><Trans i18nKey="footer.reach_out_to_us">Reach out to us</Trans></h4>
                        <div className="footer-social-wrapper">
                            <a href="https://www.instagram.com/trustroll/" target="_blank"> <img src={instagram} alt="instagram" /> </a>
                            <a href="https://twitter.com/trustroll" target="_blank"> <img src={twitter} alt="twitter" /> </a>
                            {/* <a href="https://www.facebook.com/Trustroll " target="_blank"> <img src={facebook} alt="facebook" /> </a> */}
                            <a href="https://www.youtube.com/channel/UCMX6e1OFccJtrlCRtig8gjQ" target="_blank"> <img src={youtube} alt="facebook" /> </a>
                            <a href="mailto:info@trustroll.win" target="_blank"> <img src={email} alt="email" /> </a>

                            <a href="https://discord.com/invite/4nYtG6zUJY" target="_blank"> <img src={discord} alt="discord" /> </a>
                            <a href="https://t.me/trustroll_group" target="_blank"> <img src={telegram} alt="telegram" /> </a>
                            <a href="https://medium.com/@trustroll" target="_blank"> <img src={mIcon} alt="medium" /> </a>
                            <a href="https://www.reddit.com/user/TrustRoll" target="_blank"> <img src={manIcon} alt="reddit" /> </a>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="footer-terms">
                        {/* <Link to="/tos" onClick={() => {setModulo(400); setHasPlayed(false)}} className="tos">Terms of service</Link> */}
                        {/* <Link to="/faq" onClick={() => {setModulo(401); setHasPlayed(false)}}><img src={docs} alt="document" className='docs-img'/> Docs</Link> */}
                        {/* <a href="https://docs.trustroll.win/trustroll/" target="blank" className='footer-doc-link'>
                            <img src={docs} alt="document" className='docs-img' /> <Trans i18nKey="footer.docs">Docs</Trans></a> */}
                    </div>
                    <p>&#169; 2022 Trust Roll | All rights reserved</p>
                </div>
            </div>
        </div>
    )
}