import React from 'react'
import { useTranslation, Trans } from 'react-i18next';
import flagRus from '../../assets/img/russia.png';
import flagUsa from '../../assets/img/usa.png';
import flagTur from '../../assets/img/turkey.png';
import flagGer from '../../assets/img/germany.png';
import flagJpn from '../../assets/img/japan.png';
import flagChi from '../../assets/img/china.png';
import flagSpa from '../../assets/img/spain.png';

const lngs = {
    en: { nativeName: 'en', img: flagUsa },
    ru: { nativeName: 'ru', img: flagRus },
    tur: { nativeName: 'tur', img: flagTur },
    ger: { nativeName: 'ger', img: flagGer },
    spa: { nativeName: 'spa', img: flagSpa },
    jpn: { nativeName: 'jpn', img: flagJpn },
    chi: { nativeName: 'chi', img: flagChi },
}

export default function TopHeader() {
    const { t, i18n } = useTranslation();

    let currentLang = i18n.languages[0];

    return (
        <div className='top-header '>
            <div className='site-width'>
                <div className="top-header-row">
                    <div className='lang-switcher'>
                        {Object.keys(lngs).map((lng) => (
                            <button key={lng} type="submit"
                                className={currentLang == lng ? 'lang-button active' : 'lang-button'} onClick={() => i18n.changeLanguage(lng)}>
                                <img src={lngs[lng].img} alt="Flag" />
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}