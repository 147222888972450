// 'main' for mainNet || 'test' for testNet || mainTest || mainTestLocal
const mode = process.env.REACT_APP_MODE;
const LINKS = {
  LINK: "https://trustroll.win",
  LINK_TEST: "https://testnet.trustroll.win",
};
//
// https://chainlist.org/   https://academy.binance.com/ru/articles/connecting-metamask-to-binance-smart-chain
          
const networks = {
  bsc: {
    chainId: `0x${Number(56).toString(16)}`,
    chainName: "Binance Smart Chain Mainnet",
    nativeCurrency: {
      name: "Binance Chain Native Token",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: [
      "https://bsc-dataseed1.binance.org",
      "https://bsc-dataseed2.binance.org",
      "https://bsc-dataseed3.binance.org",
      "https://bsc-dataseed4.binance.org",
      "https://bsc-dataseed1.defibit.io",
      "https://bsc-dataseed2.defibit.io",
      "https://bsc-dataseed3.defibit.io",
      "https://bsc-dataseed4.defibit.io",
      "https://bsc-dataseed1.ninicoin.io",
      "https://bsc-dataseed2.ninicoin.io",
      "https://bsc-dataseed3.ninicoin.io",
      "https://bsc-dataseed4.ninicoin.io",
      "wss://bsc-ws-node.nariox.org",
    ],
    blockExplorerUrls: ["https://bscscan.com"],
  },
  bscTest: {
    chainId: `0x${Number(97).toString(16)}`,
    chainName: "BSC Testnet",
    nativeCurrency: {
      name: "BSC Testnet",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: [
      "https://data-seed-prebsc-1-s1.binance.org:8545/",
      "https://data-seed-prebsc-2-s1.binance.org:8545/",
      "https://data-seed-prebsc-1-s2.binance.org:8545/",
      "https://data-seed-prebsc-2-s2.binance.org:8545/",
      "https://data-seed-prebsc-1-s3.binance.org:8545/",
      "https://data-seed-prebsc-2-s3.binance.org:8545/",
    ],
    blockExplorerUrls: ["https://testnet.bscscan.com"],
  },
};

const NET = {
  test: {
    SERVER_API_URL: "https://testnetapi.trustroll.win",
    BASE_SCAN_URL: "https://testnet.bscscan.com/",

    SWITCH_BUTTON: {
      LINKS,
      TEXT: "BSC Testnet",
    },
    BLOCKCHAIN: {
      ...[networks["bscTest"]],
      CHAIN_ID: 97, //"0x61",
      NETWORK_NAME: "bscTest",
      RPC_URL: "https://data-seed-prebsc-1-s1.binance.org:8545/",
      CROUPIER: process.env.REACT_APP_CROUPIER_PRIVATE_KEY_TEST_NET,
      SECRET_SIGNER: process.env.REACT_APP_SECRET_SIGNER_PRIVATE_KEY_TEST_NET,
     // CONTRACT_ADDRESS: process.env.REACT_APP_CONTRACT_ADDRESS_TEST_NET,
      CONTRACT_ADDRESS: '0x08B1bd3B20FF82ad8bfE97f603A0bc8Bb622542A',
    },
  },

  testLocal: {
    SERVER_API_URL: "http://localhost:5001",
    BASE_SCAN_URL: "https://testnet.bscscan.com/",
    SWITCH_BUTTON: {
      LINKS,
      TEXT: "BSC Testnet",
    },
    BLOCKCHAIN: {
      ...[networks["bscTest"]],
      CHAIN_ID: 97, //"0x61",
      NETWORK_NAME: "bscTest",
      RPC_URL: "https://data-seed-prebsc-1-s1.binance.org:8545/",
      CROUPIER: process.env.REACT_APP_CROUPIER_PRIVATE_KEY_TEST_NET,
      SECRET_SIGNER: process.env.REACT_APP_SECRET_SIGNER_PRIVATE_KEY_TEST_NET,
      CONTRACT_ADDRESS: process.env.REACT_APP_CONTRACT_ADDRESS_TEST_NET,
    },
  },

  main: {
    SERVER_API_URL: "https://api.trustroll.win",
    BASE_SCAN_URL: "https://bscscan.com/",

    SWITCH_BUTTON: {
      LINKS,
      TEXT: "BSC Mainnet",
    },
    BLOCKCHAIN: {
      //TO DO: WALLET_CONFIG:
      ...[networks["bsc"]],
      NETWORK_NAME: "bsc",
      CHAIN_ID: 56, //"0x38",
      RPC_URL: "https://bsc-dataseed.binance.org/",
      CROUPIER: process.env.REACT_APP_CROUPIER_PRIVATE_KEY,
      SECRET_SIGNER: process.env.REACT_APP_SECRET_SIGNER_PRIVATE_KEY,
      CONTRACT_ADDRESS: process.env.REACT_APP_CONTRACT_ADDRESS,
    },
  },
  mainTest: {
    SERVER_API_URL: "https://apimainnensddsdfsdfs.trustroll.win",
    BASE_SCAN_URL: "https://bscscan.com/",

    SWITCH_BUTTON: {
      LINKS,
      TEXT: "PLAY ON TESTNET",
    },
    BLOCKCHAIN: {
      ...[networks["bsc"]],
      NETWORK_NAME: "bsc",
      CHAIN_ID: 56, //"0x38",
      RPC_URL: "https://bsc-dataseed.binance.org/",
      CROUPIER: process.env.REACT_APP_CROUPIER_PRIVATE_KEY,
      SECRET_SIGNER: process.env.REACT_APP_SECRET_SIGNER_PRIVATE_KEY,
      CONTRACT_ADDRESS: process.env.REACT_APP_CONTRACT_ADDRESS,
    },
  },
  mainTestLocal: {
    SERVER_API_URL: "http://localhost:5001",
    BASE_SCAN_URL: "https://bscscan.com/",

    SWITCH_BUTTON: {
      LINKS,
      TEXT: "PLAY ON TESTNET",
    },
    BLOCKCHAIN: {
      ...[networks["bsc"]],
      NETWORK_NAME: "bsc",
      CHAIN_ID: 56, //"0x38",
      RPC_URL: "https://bsc-dataseed.binance.org/",
      CROUPIER: process.env.REACT_APP_CROUPIER_PRIVATE_KEY,
      SECRET_SIGNER: process.env.REACT_APP_SECRET_SIGNER_PRIVATE_KEY,
      CONTRACT_ADDRESS: process.env.REACT_APP_CONTRACT_ADDRESS,
    },
  },
};

// let mainOrTestVersion = false; //main = true || test = false
// mainOrTestVersion = false;

// const PORT = `http://45.15.24.63:5001`;

// const PORT = `192.168.31.217:5001`;
// let PORT = "https://testnetapi.trustroll.win"; //'http://localhost:5001';//`https://api.trustroll.win`;
// const PORT_PROD = "https://api.trustroll.win";
// let SITE_MAIN_LINK = "https://trustroll.win";
// const PORT_PROD = "http://192.168.31.56:5001";
// const DEV_LINK_TO_SERVER = "http://localhost:5001";

// const BNB_TESTNET_BASE_LINK = "https://ropsten.etherscan.io/";
// let BNB_TESTNET_BASE_LINK = "https://testnet.bscscan.com/"; //for bnb
// let BNB_MAINNET_BASE_LINK = "https://bscscan.com/";
// let LINK_WHITEPAPER = SITE_MAIN_LINK + "/whitepaper.pdf";

// let chainIdTest = "0x38";

// if (mainOrTestVersion) {
//   PORT = PORT_PROD;
//   BNB_TESTNET_BASE_LINK = BNB_MAINNET_BASE_LINK;
//   SITE_MAIN_LINK = "https://testnet.trustroll.win/";
// } else {
//   BNB_MAINNET_BASE_LINK = BNB_TESTNET_BASE_LINK;
//   chainIdTest = "0x61";
// }

// https://ropsten.etherscan.io/address
// https://ropsten.etherscan.io/tx

// console.log();
// let mainOrTestVersion = false; //main = true || test = false
// if (mode === 'main') mainOrTestVersion = true
// // mainOrTestVersion = false;

// const SERVER_IP = "http://45.15.24.63";
// // const PORT = `http://45.15.24.63:5001`;

// // const PORT = `192.168.31.217:5001`;
// let PORT = "https://testnetapi.trustroll.win"; //'http://localhost:5001';//`https://api.trustroll.win`;
// const PORT_PROD = "https://api.trustroll.win";
// let SITE_MAIN_LINK = "https://trustroll.win";
// // const PORT_PROD = "http://192.168.31.56:5001";
// const DEV_LINK_TO_SERVER = "http://localhost:5001";

// // const BNB_TESTNET_BASE_LINK = "https://ropsten.etherscan.io/";
// let BNB_TESTNET_BASE_LINK = "https://testnet.bscscan.com/"; //for bnb
// let BNB_MAINNET_BASE_LINK = "https://bscscan.com/";
// let LINK_WHITEPAPER = SITE_MAIN_LINK + "/whitepaper.pdf";

// let chainIdTest = "0x38";

// if (mainOrTestVersion) {
//   PORT = PORT_PROD;
//   BNB_TESTNET_BASE_LINK = BNB_MAINNET_BASE_LINK;
//   SITE_MAIN_LINK = "https://testnet.trustroll.win/";
// } else {
//   BNB_MAINNET_BASE_LINK = BNB_TESTNET_BASE_LINK;
//   chainIdTest = "0x61";
// }


export default { 
  NET: NET[mode],
  // PORT,
  // mainOrTestVersion,
  // chainIdTest,
  // // PORT_PROD,
  // LINK_WHITEPAPER,
  // SITE_MAIN_LINK,
  // SERVER_IP,
  // BNB_TESTNET_BASE_LINK,
  // BNB_MAINNET_BASE_LINK,
 };

// export default function constants() {
//   return {
//     NET: NET[mode],
//   };
//   // PORT,
//   // mainOrTestVersion,
//   // chainIdTest,
//   // PORT_PROD,
//   // SERVER_IP,
//   // BNB_TESTNET_BASE_LINK,
//   // BNB_MAINNET_BASE_LINK,
// }

// export default function constants() {
//   return {
//     NET: NET[mode],
//   };
//   // PORT,
//   // mainOrTestVersion,
//   // chainIdTest,
//   // PORT_PROD,
//   // SERVER_IP,
//   // BNB_TESTNET_BASE_LINK,
//   // BNB_MAINNET_BASE_LINK,
// }

// Network Name: Smart Chain
// New RPC URL: https://bsc-dataseed.binance.org/
// ChainID: 56
// Symbol: BNB
// Block Explorer URL: https://bscscan.com

// Testnet
// Network Name: Smart Chain - Testnet
// New RPC URL: https://data-seed-prebsc-1-s1.binance.org:8545/
// ChainID: 97
// Symbol: BNB
// Block Explorer URL: https://testnet.bscscan.com
