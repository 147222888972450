import React, { useContext } from "react";
import BlockchainContext from "../BlockchainContext.js";

export default function ButtonRed({ text, getWalletBtnClick }) {
    const blockchainContext = useContext(BlockchainContext)
    // const {handleNetworkSwitch } = blockchainContext

    return (
        <div className='button-red' onClick={getWalletBtnClick}>
            <div className='button-red-text'>{text}</div>
        </div>
    )
} 