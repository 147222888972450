import React from 'react';


export default function TOS() {

    return (
        <div className="site-width main-content-padding">
            <h1 className="title">TERMS OF SERVICE</h1>
            <p className="text"> 
                The trustroll.win website is an interface to the smart contract based on blockchain that accepts and sends bnb in return, depending on the external factors.
            </p>
            <p className="text">
                We reserve the right to make any kind of changes or modifications to the site and the game rules without prior notice. Our service recommends that you regularly check for the latest updates.
            </p >
            <p className="text">
                We are not able to check the legality of all transactions in all jurisdictions or provide legal advice in these areas.
            </p>
            <p className="text">
                Playing games based on a smart contract, you may lose certain amounts of tokens. Our website does not take any responsibility for the outcome of the game played.
            </p>
        </div>
    )
}

