import React from 'react';
import ReactDOM from 'react-dom';
import constants from '../constance';
import {Link} from 'react-router-dom';

const open = (e)=>{
    let openEls = document.getElementsByClassName('faq-open');
    let openElements = Array.from(openEls);
    openElements.map( el => ReactDOM.findDOMNode(el).classList.toggle('faq-open'));
    e.currentTarget.classList.toggle('faq-open');
}

export default function FAQ({contract}) {

    const {NET} = constants.NET;
    const BASE_SCAN_URL = NET.BASE_SCAN_URL;

    return (
        <div className="site-width main-content-padding">
            <h1 className="faq-title title">FREQUENTLY ASKED QUESTIONS</h1>

            <div className='faq-unfold' onClick={open}>
                <p className="faq-subtitle">What is provably fair on-chain gambling? </p>
                <div className='faq-bottom'>
                    <p className="text">
                        Simply put, <a className="faq-link" href="https://trustroll.win/whitepaper.pdf" target="_blank">provably fair</a> means that any bet outcome can be independently verified and that the house or other players have no means of tampering with the result.    
                    </p>  
                </div>              
            </div>

            <div className='faq-unfold' onClick={open}>
                <p className="faq-subtitle">Is Trustroll provably fair?</p>
                <div className='faq-bottom'>
                    <p className="text">   
                        Yes. The whole gameplay is controlled by Binance Smart Chain that computes random numbers based on house inputs and blockchain data (<a className="faq-link" href="https://medium.com/coinmonks/block-hash-18514618c10d" target="_blank">block hashes</a>). 
                        Any party can audit the contract as well as inspect any transaction to make sure that neither <Link to="/" className="faq-link" > Trustroll </Link> or malicious players are influencing the results. 
                    </p>
                </div>
            </div>
            
            <div className='faq-unfold' onClick={open}>
                <p className="faq-subtitle">How are you different from the other gambling sites?</p>
                <div className='faq-bottom'>
                    <p className="text">
                        Placing a bet on <Link to="/" className="faq-link"> Trustroll </Link> has much lower transaction fee compared to competing houses - this allows supporting bets as low as 0,01 BNB. Our games are very simple & easily understandable, just like tossing a coin or rolling a dice. 
                    </p>   
                    <p className="text">
                        And, of course, we have jackpot!
                    </p>                   
                </div>
            </div>

            <div className='faq-unfold' onClick={open}>
                <p className="faq-subtitle">Is there any catch? Explain how it works, like I’m five.</p>
                <div className='faq-bottom'>
                    <p className="text">
                        This is where we have to get a bit technical:
                    </p>    
                    <ul className="faq-padding text">
                        <li className="faq-disc">
                            <p> <Link to="/" className="faq-link" > Trustroll </Link> picks a secret random number and provides you with its hash.</p> 
                        </li>
                        <li className="faq-disc"> 
                            <p>You send your bet in Binance transaction to our <a className="faq-link" href={BASE_SCAN_URL + `address/` + contract +`#code`} target="_blank">smart contract</a> along with the <a className="faq-link" href="https://en.wikipedia.org/wiki/SHA-3" target="_blank">hash</a> from previous step.</p>  
                        </li>
                        <li className="faq-disc">
                            <p>At this point <Link to="/" className="faq-link" > Trustroll </Link> has already commited to a number, prior to you chosing an outcome.</p>   
                        </li>
                        <li className="faq-disc">
                            <p>Once your transaction is confirmed by the network, the contract stores the <a className="faq-link" href="https://en.wikipedia.org/wiki/SHA-3" target="_blank">hash</a> and bet details.</p>   
                        </li>
                        <li className="faq-disc">
                            <p>Out croupier bot “reveals” the number by sending a bet settling transaction.</p>   
                        </li>
                        <li className="faq-disc">
                            <p>The contract accepts the transaction if and only if the <a className="faq-link" href="https://en.wikipedia.org/wiki/SHA-3" target="_blank">hash</a> of provided number is the same as the stored one.   </p>   
                        </li>
                        <li className="faq-disc">  
                            <p>The contract mixes the number and <a className="faq-link" href="https://medium.com/coinmonks/block-hash-18514618c10d" target="_blank">block hash</a> of the bet transaction to get a random number.</p>   
                        </li>
                        <li className="faq-disc">
                            <p>The contract decides whether you won or lost and sends you the winning amount of BNB.</p>   
                        </li>
                    </ul>       
                    <p className="text">
                        Can <Link to="/" className="faq-link" > Trustroll </Link> tamper with the results? Nope, as the contract keeps track of secret number’s <a className="faq-link" href="https://en.wikipedia.org/wiki/SHA-3" target="_blank">hash</a>, meaning the house cannot change the number after the bet has 
                        been accepted. Mixing the <a className="faq-link" href="https://medium.com/coinmonks/block-hash-18514618c10d" target="_blank">block hash</a> with the numbers makes the result totally random yet disallows miners from crafting winning bets. On the other hand, <Link to="/" className="faq-link" > Trustroll </Link> themselves cannot control bet outcomes either because of <a className="faq-link" href="https://medium.com/coinmonks/block-hash-18514618c10d" target="_blank">block hash</a> component. This is a well-known “commitment scheme” which enables <Link to="/" className="faq-link" > Trustroll </Link> to provide gambling-grade random number generation allowing for big bets, jackpots and quick settlements while being fully transparent. 
                    </p>        
                </div>            
            </div>

            <div className='faq-unfold' onClick={open}>
                <p className="faq-subtitle">What if I want to really verify that everything you say is actually true?</p>
                <div className='faq-bottom'>
                    <p className="text">
                        Feel free to study our Smart Contract - it’s available on Github. In case you have any questions or hesitations, 
                        drop us a line via <a className="faq-link" href="https://t.me/trustroll_group" target="_blank"> Telegram </a>, 
                        <a className="faq-link" href="https://twitter.com/trustroll" target="_blank"> Twitter </a> or <a className="faq-link" href="mailto:info@trustroll.win"> e-mail </a>. 
                    </p>
                </div> 
            </div>   

            <div className='faq-unfold' onClick={open}>
                <p className="faq-subtitle">What are house fees?</p>
                <div className='faq-bottom'>
                    <p className="text">
                        Every bet is deducted 1% in favour of the house (to help us pay the bills and keep the game running) and 1% more gets accumulated in the jackpot. 
                        Anyone, who bets high enough (at the moment it is 0,1 BNB and up) has a chance of winning the whole amount of jackpot! 
                    </p>
                </div>
            </div>
            <p className="text faq-last-phrase">
                Happy gambling! 
            </p>
        </div>
    )
}

