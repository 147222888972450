import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          // here we will place our translations...
          title: {
            maintitle: "Bet and Get Lucky",
          },
          description: {
            maindescription:
              "Enjoy our decentralized dice games based on a smart contract! Play fair and stay safe!",
          },
          statistics: {
            wagers: "24h wagers",
            bets: "bets",
            recentjackpot: "Recent jackpot",
            wonby: "Won by",
            topwinner: "24h top winners",
          },
          menu: {
            coinflip: "Coin flip",
            dice: "Dice",
            twodice: "Two dice",
            trustroll: "Trustroll",
            docs: "Docs",
            net: "Select a network",
            connectwallet: "connect wallet",
            wrong_network: "wrong network!",
          },
          gametext: {
            coinflip:
              "Odds of winning are 50/50, payouts 1.98x. Try your luck!",
            dice: "Betting on side numbers 1 to 6, payouts up to 5.82x. Bet and win!",
            twodice:
              "Betting on the sum of numbers, 2 to 12, payouts up to 35.64x. Stop waiting — start playing!",
            trustroll:
              "Any odds of winning, 1 to 98%, payouts up to 99x —It's all up to you!",
            play: "Play now",
          },
          footer: {
            ourgames: "Our games",
            coinflip: "Coin flip",
            dice: "Dice",
            twodice: "Two dice",
            trustroll: "Trustroll",
            roulette: "Roulette",
            blockchainfriends: "Blockchain & Friends",
            yourbalance: "Your Balance",
            walletisnoconnected: "wallet is not connected",
            network: "Network",
            smartcontract: "Smart contract",
            free_bnb_testnet: "Free BNB testnet",
            featured_on: "Featured on",
            reach_out_to_us: "Reach out to us",
            docs: "Docs",
          },
          game: {
            yourbet: "Your bet",
            warning: {
              minbet: "Min bet 0.01 BNB!",
              yourwallet: "Your wallet have small balance!",
              too_big_sum: "Attention! Too big sum for contract!",
            },
            coinflip: {
              title: "Choose coin side to bet on",
            },
            dice: {
              title: "Choose the dice number(s) to bet on",
            },
            two_dice: {
              title: "Choose sum of dice to bet on",
            },
            trustroll: {
              title: "Adjust your win chance",
            },
            roulette: {
              title: "Adjust your win chance",
            },
            won: "You won!",
            lost: "You lost!",
            do_not_give_up:
              "Don't give up! Maybe your jackpot is already here?",
            jackpot_roll_was: "Jackpot roll was",
            bet_submitted: "Bet submitted!",
            waiting_for_bnb: "Waiting for BNB...",
          },
          button: {
            play: "Play now",
          },
          outputwin: {
            winning_chance: "Winning chance",
            winning_bet: "Winning bet pays",
            you_will_win: "You will win",
            fee: "fee",
            jackpot_contains: "Jackpot contains",
            lucky_number: "Lucky number is",
          },
          errors: {
            settlebet: "Settle Bet Failed",
            connect_support: "Connect support",
          },
          history: {
            all_bets: "All bets",
            all: "All",
            my_bets: "My bets",
            player: "Player",
            bet: "Bet",
            result: "Result",
            jackpot: "Jackpot",
            not_available_data: "No available data",
            bottom: {
              address: "address",
              bet: "bet",
              bet_on: "bet on",
              bet_trx: "bet trx",
              aha3_secret: "aha3 (secret)",
              secret: "secret",
              aha3_bet_block: "aha3(bet block)",
              aha3_blk_secret: "aha3(blk + secret)",
              aha3_mod: "aha3 mod",
              jackpot: "jackpot",
              wins: "wins",
            },
          },
          docs: "https://docs.trustroll.win/trustroll/",
        },
      },
    spa: {
        translation: {
          // here we will place our translations...
          title: {
            maintitle: "Apuesta y ten suerte",
          },
          description: {
            maindescription:
              "¡Disfruta de nuestros juegos de dados descentralizados basados en un contrato inteligente! ¡Juega limpio y mantente seguro!",
          },
          statistics: {
            wagers: "Apuestas 24h",
            bets: "Apuestas",
            recentjackpot: "Jackpot reciente",
            wonby: "Ganado por",
            topwinner: "Los principales ganadores en 24 horas",
          },
          menu: {
            coinflip: "Coin flip",
            dice: "Dice",
            twodice: "Two dice",
            trustroll: "Trustroll",
            docs: "Docs",
            net: "Selecciona una red",
            connectwallet: "Conectar monedero",
            wrong_network: "¡Una red equivocada!",
          },
          gametext: {
            coinflip:
              "Las probabilidades de ganar son 50/50, los pagos 1.98x. ¡Prueba suerte!",
            dice: "Apuesta a los números del 1 al 6, pagos de hasta 5,82x. ¡Apuesta y gana!",
            twodice:
              "Apuesta a la suma de números, del 2 al 12, pagos de hasta 35,64x. No esperes más - ¡Empieza a jugar!",
            trustroll:
              "Cualquier probabilidad de ganar, del 1 al 98%, pagos de hasta el 99% - ¡Todo depende de ti!",
            play: "Juega ahora",
          },
          footer: {
            ourgames: "Nuestros juegos",
            coinflip: "Coin flip",
            dice: "Dice",
            twodice: "Two dice",
            trustroll: "Trustroll",
            roulette: "Roulette",
            blockchainfriends: "Blockchain & Amigos",
            yourbalance: "Tu saldo",
            walletisnoconnected: "el monedero no está conectado ",
            network: "Red",
            smartcontract: "Smart contract",
            free_bnb_testnet: "BNB testnet gratis",
            featured_on: "Destacado en ",
            reach_out_to_us: "Contacta con nosotros",
            docs: "Docs",
          },
          game: {
            yourbet: "Tu apuesta",
            warning: {
              minbet: "Apuesta mínima 0.01 BNB!",
              yourwallet: "Tu cartera tiene poco saldo",
              too_big_sum: "¡Atención! ¡Una suma demasiado grande para el contrato!",
            },
            coinflip: {
              title: "Elige la cara de la moneda para apostar",
            },
            dice: {
              title: "Elige el número o los números de los dados a los que vas a apostar",
            },
            two_dice: {
              title: "Elige la suma de los dados para apostar",
            },
            trustroll: {
              title: "Ajusta tu posibilidad de ganar",
            },
            roulette: {
              title: "Ajusta tu posibilidad de ganar",
            },
            won: "Has ganado",
            lost: "Has perdido",
            do_not_give_up:
              "¡No te rindas! ¿Quizás tu Jackpot ya está aquí?",
            jackpot_roll_was: "La tirada al Jackpot fue",
            bet_submitted: "¡Apuesta registrada!",
            waiting_for_bnb: "Esperando a BNB...",
          },
          button: {
            play: "Juega ahora",
          },
          outputwin: {
            winning_chance: "Probabilidad de ganar",
            winning_bet: "La apuesta ganadora se paga",
            you_will_win: "Tú ganarás",
            fee: "de cuota",
            jackpot_contains: "El Jackpot contiene",
            lucky_number: "El número de la suerte es",
          },
          errors: {
            settlebet: "Liquidación de la apuesta fallida ",
            connect_support: "Contactar con el servicio de atención al cliente",
          },
          history: {
            all_bets: "Todas las apuestas",
            all: "Todas las",
            my_bets: "Mis",
            player: "Jugador",
            bet: "Apuesta",
            result: "Resultado",
            jackpot: "Jackpot",
            not_available_data: "No hay datos disponibles",
            bottom: {
              address: "address",
              bet: "bet",
              bet_on: "bet on",
              bet_trx: "bet trx",
              aha3_secret: "aha3 (secret)",
              secret: "secret",
              aha3_bet_block: "aha3(bet block)",
              aha3_blk_secret: "aha3(blk + secret)",
              aha3_mod: "aha3 mod",
              jackpot: "jackpot",
              wins: "wins",
            },
          },
          docs: "https://docs.trustroll.win/trustroll/",
        },
      },
      ger: {
        translation: {
          // here we will place our translations...
          title: {
            maintitle: "Wetten und Glück haben",
          },
          description: {
            maindescription:
              "Genießen Sie unsere dezentralen Würfelspiele, die auf einem intelligenten Vertrag basieren! Spielen Sie fair und gehen Sie auf Nummer sicher!",
          },
          statistics: {
            wagers: "24h Wetten",
            bets: "Wetten",
            recentjackpot: "Jüngster Jackpot",
            wonby: "Gewonnen von",
            topwinner: "24-Stunden-Topsieger",
          },
          menu: {
            coinflip: "Coin flip",
            dice: "Dice",
            twodice: "Two dice",
            trustroll: "Trustroll",
            docs: "Docs",
            net: "Wählen Sie ein Netzwerk",
            connectwallet: "Geldbörse verbinden",
            wrong_network: "falsches Netzwerk!",
          },
          gametext: {
            coinflip:
              "Die Gewinnchancen sind 50/50, die Auszahlung 1,98x. Versuchen Sie Ihr Glück!",
            dice: "Wetten auf die Augenzahlen 1 bis 6, Auszahlungen bis zu 5,82x. Wetten und gewinnen!",
            twodice:
              "Wetten auf die Summe der Zahlen von 2 bis 12, Auszahlungen bis zu 35,64x. Schluss mit dem Warten - beginnen Sie zu spielen!",
            trustroll:
              "Beliebige Gewinnchancen, 1 bis 98%, Auszahlungen bis zu 99% - Es liegt ganz bei Ihnen!",
            play: "Jetzt spielen ",
          },
          footer: {
            ourgames: "Unsere Spiele",
            coinflip: "Coin flip",
            dice: "Dice",
            twodice: "Two dice",
            trustroll: "Trustroll",
            roulette: "Roulette",
            blockchainfriends: "Blockchain & Freunde",
            yourbalance: "Ihr Kontostand",
            walletisnoconnected: "weldbörse ist nicht verbunden ",
            network: "Netzwerk",
            smartcontract: "Smart contract",
            free_bnb_testnet: "Kostenloses BNB-Testnetz",
            featured_on: "Vorgestellt auf",
            reach_out_to_us: "Erreichen Sie uns",
            docs: "Docs",
          },
          game: {
            yourbet: "Ihr Einsatz",
            warning: {
              minbet: "Mindestgebot 0.01 BNB!",
              yourwallet: "Ihr Portemonnaie hat ein kleines Guthaben!",
              too_big_sum: "Aufmerksamkeit! Zu große Summe für Vertrag!",
            },
            coinflip: {
              title:
                "Wählen Sie die Seite der Münze, auf die Sie setzen möchten",
            },
            dice: {
              title:
                "Wählen Sie die Würfelzahl(en), auf die Sie setzen möchten",
            },
            two_dice: {
              title:
                "Wählen Sie die Summe der Würfel, auf die Sie setzen möchten",
            },
            trustroll: {
              title: "Anpassung der Gewinnchance",
            },
            roulette: {
              title: "Anpassung der Gewinnchance",
            },
            won: "Sie haben gewonnen!",
            lost: "Sie verlieren!",
            do_not_give_up:
              "Geben Sie nicht auf! Vielleicht ist Ihr Jackpot bereits hier?",
            jackpot_roll_was: "Die Jackpot-Rolle war",
            bet_submitted: "Wette eingereicht!",
            waiting_for_bnb: "Warten auf BNB...",
          },
          button: {
            play: "Jetzt spielen",
          },
          outputwin: {
            winning_chance: "Gewinnchance",
            winning_bet: "Gewinnchance",
            you_will_win: "Sie werden gewinnen",
            fee: "gebühr",
            jackpot_contains: "Jackpot enthält",
            lucky_number: "Glückszahl ist",
          },
          errors: {
            settlebet: "Wette abschließen gescheitert",
            connect_support: "Unterstützung verbinden ",
          },
          history: {
            all_bets: "Alle Wetten",
            all: "Alle",
            my_bets: "Meine Einsätze ",
            player: "Spieler",
            bet: "Wette ",
            result: "Ergebnis",
            jackpot: "Jackpot",
            not_available_data: "Kostenloses BNB-Testnetz",
            bottom: {
              address: "address",
              bet: "bet",
              bet_on: "bet on",
              bet_trx: "bet trx",
              aha3_secret: "aha3 (secret)",
              secret: "secret",
              aha3_bet_block: "aha3(bet block)",
              aha3_blk_secret: "aha3(blk + secret)",
              aha3_mod: "aha3 mod",
              jackpot: "jackpot",
              wins: "wins",
            },
          },
          docs: "https://docs.trustroll.win/trustroll/",
        },
      },
      tur: {
        translation: {
          // here we will place our translations...
          title: {
            maintitle: "Bahis Oynayın ve Şans Yüzünüze Gülsün",
          },
          description: {
            maindescription:
              "Akıllı sözleşmeye dayanan merkezi olmayan zar oyunlarımızın keyfini çıkarın! Adil oynayın ve güvende kalın!",
          },
          statistics: {
            wagers: "24 saatlik bahisler",
            bets: "Bahisleri",
            recentjackpot: "Son jackpot",
            wonby: "Kazanmak",
            topwinner: "24 saat en çok kazananlar",
          },
          menu: {
            coinflip: "Coin flip",
            dice: "Dice",
            twodice: "Two dice",
            trustroll: "Trustroll",
            docs: "Docs",
            net: "Bir ağ seçin",
            connectwallet: "cüzdanı bağla",
            wrong_network: "wrong network!",
          },
          gametext: {
            coinflip: "Kazanma oranı 50/50, ödeme 1.98x. Şansını dene!",
            dice: "1 ila 6 arasında görünen numaraya göre bahis, 5.88x’e kadar ödeme. Bahis oyna ve kazan!",
            twodice:
              "2 ila 12 arasında sayıların toplamına göre bahis, 35.64x'e kadar ödeme. Oynamaya başlayın!",
            trustroll:
              "Kazanma ihtimali, 1 ile %97, %98'e varan kazanma ihtimalli ödeme—Hepsi size kalmış!",
            play: "Şimdi oyna",
          },
          footer: {
            ourgames: "Oyunlarımız",
            coinflip: "Coin flip",
            dice: "Dice",
            twodice: "Two dice",
            trustroll: "Trustroll",
            roulette: "Roulette",
            blockchainfriends: "Blockchain ve Arkadaşları",
            yourbalance: "Bakiyeniz",
            walletisnoconnected: "cüzdan bağlı değil",
            network: "Ağ",
            smartcontract: "Smart contract",
            free_bnb_testnet: "Ücretsiz BNB test ağı",
            featured_on: "İki zar",
            reach_out_to_us: "Bize ulaşın",
            docs: "Docs",
          },
          game: {
            yourbet: "Bahsiniz",
            warning: {
              minbet: "Minimum bahis 0.01 BNB!",
              yourwallet: "Cüzdanınızda yeterli bakiye yok!",
              too_big_sum: "Dikkat! Sözleşme için çok büyük meblağ!",
            },
            coinflip: {
              title: "Madeni paranın bahis oynayacağınız tarafını seçin",
            },
            dice: {
              title: "Bahis oynamak istediğiniz zar sayısını seçin",
            },
            two_dice: {
              title: "Bahis oynamak istediğiniz zar sayısını seçin",
            },
            trustroll: {
              title: "Kazanma şansınızı düzenleyin",
            },
            roulette: {
              title: "Kazanma şansınızı düzenleyin",
            },
            won: "Sie haben gewonnen!",
            lost: "Sie verlieren!",
            do_not_give_up: "Pes etmeyin! Belki de büyük ikramiye buradadır? ",
            jackpot_roll_was: "Jackpot rulosu",
            bet_submitted: "Bahis sunuldu!",
            waiting_for_bnb: "BNB'yi beklemek...",
          },
          button: {
            play: "Şimdi oyna",
          },
          outputwin: {
            winning_chance: "Kazanma şansı",
            winning_bet: "Kazanan bahse ödeme yapılır",
            you_will_win: "Kazanacaksınız",
            fee: "ücret",
            jackpot_contains: "Büyük ikramiyede",
            lucky_number: "Şanslı numara",
          },
          errors: {
            settlebet: "Bahis sonlandırma başarısız oldu",
            connect_support: "Destek ile iletişime geçin",
          },
          history: {
            all_bets: "Tüm bahisler",
            all: "Tümü",
            my_bets: "Bahislerim",
            player: "Oyuncu",
            bet: "Bahis",
            result: "Sonuç",
            jackpot: "Ikramiye",
            not_available_data: "Kullanılabilir veri yok",
            bottom: {
              address: "address",
              bet: "bet",
              bet_on: "bet on",
              bet_trx: "bet trx",
              aha3_secret: "aha3 (secret)",
              secret: "secret",
              aha3_bet_block: "aha3(bet block)",
              aha3_blk_secret: "aha3(blk + secret)",
              aha3_mod: "aha3 mod",
              jackpot: "jackpot",
              wins: "wins",
            },
          },
          docs: "https://docs.trustroll.win/trustroll/v/turkish/",
        },
      },
      jpn: {
        translation: {
          // here we will place our translations...
          title: {
            maintitle: "ベット＆ラッキーゲット",
          },
          description: {
            maindescription:
              "スマートコントラクトに基づいた分散型のサイコロゲームをお楽しみください！公平かつ安全なプレイをお楽しみください！",
          },
          statistics: {
            wagers: "24時間の賭け",
            bets: "ベット",
            recentjackpot: "最新のジャックポット",
            wonby: "勝利",
            topwinner: "24時間以内の上位勝利者",
          },
          menu: {
            coinflip: "Coin flip",
            dice: "Dice",
            twodice: "Two dice",
            trustroll: "Trustroll",
            docs: "Docs",
            net: "ネットワークを選択する",
            connectwallet: "ウォレットを繋げる",
            wrong_network: "不正解ネットワーク!",
          },
          gametext: {
            coinflip:
              "勝率は50/50でペイアウトは1.98倍です。あなたの運を試してみてください！",
            dice: "サイドナンバーの1から6で最大ペイアウトは5.82倍です。賭けて勝利を手にしましょう！",
            twodice:
              "2から12までの数字の合計に賭けてることで、最大35.64倍のペイアウトが行われます。待つのをやめて早速プレイを始めてください！",
            trustroll:
              "— 勝率は1〜98％でペイアウトは最大99％まで上がります。全てはあなたにかかっています！",
            play: "今すぐプレイ",
          },
          footer: {
            ourgames: "私たちのゲーム",
            coinflip: "Coin flip",
            dice: "Dice",
            twodice: "Two dice",
            trustroll: "Trustroll",
            roulette: "Roulette",
            blockchainfriends: "ブロックチェーン& 友達",
            yourbalance: "あなたの残高",
            walletisnoconnected: "ウォレットが設定されていません",
            network: "ネットワーク",
            smartcontract: "Smart contract",
            free_bnb_testnet: "無料のBNBのテストネット",
            featured_on: "特集",
            reach_out_to_us: "お問い合わせ",
            docs: "Docs",
          },
          game: {
            yourbet: "あなたのベット",
            warning: {
              minbet: "最低額ベット 0.01 BNB!",
              yourwallet: "あなたのウォレットの残高が少ないです！",
              too_big_sum: "注意！契約額が大きすぎます！",
            },
            coinflip: {
              title: "ベットするコインを選択する",
            },
            dice: {
              title: "ベットするサイコロの数を選択する",
            },
            two_dice: {
              title: "ベットするサイコロの合計数を選択する",
            },
            trustroll: {
              title: "勝利へのチャンスを掴む",
            },
            roulette: {
              title: "勝利へのチャンスを掴む",
            },
            won: "あなたの勝ち",
            lost: "あなたの負け",
            do_not_give_up:
              "まだ諦めないでください！あなたのジャックポットがここで見つかるかもしれませんよ？",
            jackpot_roll_was: "ジャックポットロールは",
            bet_submitted: "ベット提出!",
            waiting_for_bnb: "BNBを待ち望む",
          },
          button: {
            play: "今すぐプレイ",
          },
          outputwin: {
            winning_chance: "勝利チャンス",
            winning_bet: "勝ちベットの配当",
            you_will_win: "あなたは勝利するでしょう",
            fee: "の手数料",
            jackpot_contains: "~ ジャックポットは~を含みます",
            lucky_number: "ラッキーナンバーは…",
          },
          errors: {
            settlebet: "ベット決済に失敗しました",
            connect_support: "のサポートを繋げる",
          },
          history: {
            all_bets: "全てのベット",
            all: "全て",
            my_bets: "私のベット",
            player: "プレイヤー",
            bet: "ベット",
            result: "結果",
            jackpot: "ジャックポット",
            not_available_data: "利用可能なデータはありません",
            bottom: {
              address: "address",
              bet: "bet",
              bet_on: "bet on",
              bet_trx: "bet trx",
              aha3_secret: "aha3 (secret)",
              secret: "secret",
              aha3_bet_block: "aha3(bet block)",
              aha3_blk_secret: "aha3(blk + secret)",
              aha3_mod: "aha3 mod",
              jackpot: "jackpot",
              wins: "wins",
            },
          },
          docs: "https://docs.trustroll.win/trustroll/",
        },
      },
      chi: {
        translation: {
          // here we will place our translations...
          title: {
            maintitle: "下注并撞到好运",
          },
          description: {
            maindescription:
              "享受我们基于智能合约的去中心化骰子游戏吧！公平游戏，有安全感！",
          },
          statistics: {
            wagers: "24小时投注",
            bets: "下注",
            recentjackpot: "最近的大奖",
            wonby: "中奖人：",
            topwinner: "24小时顶级赢家",
          },
          menu: {
            coinflip: "Coin flip",
            dice: "Dice",
            twodice: "Two dice",
            trustroll: "Trustroll",
            docs: "Docs",
            net: "选择一个网络",
            connectwallet: "连接钱包",
            wrong_network: "错误的网络",
          },
          gametext: {
            coinflip:
              "中奖几率是50/50，奖金是1.98倍。试试你的运气吧！",
            dice: "在边号1到6上下注，奖金最高为5.82倍。下注就赢了！",
            twodice:
              "从2到12，以数字的总和投注，奖金最高达35.64倍。别等了——赶快开始玩吧！",
            trustroll:
              "1到98%的任意中奖几率，奖金高达99%——一切都取决于你！",
            play: "立即开玩",
          },
          footer: {
            ourgames: "你输了",
            coinflip: "Coin flip",
            dice: "Dice",
            twodice: "Two dice",
            trustroll: "Trustroll",
            roulette: "Roulette",
            blockchainfriends: "Blockchain & 好友",
            yourbalance: "您的余额",
            walletisnoconnected: "钱包未连接",
            network: "网络",
            smartcontract: "Smart contract",
            free_bnb_testnet: "Free BNB testnet",
            featured_on: "特色",
            reach_out_to_us: "联系我们",
            docs: "Docs",
          },
          game: {
            yourbet: "你的投注",
            warning: {
              minbet: "最小赌注 0.01 BNB!",
              yourwallet: "你钱包内的余额太少！",
              too_big_sum: "注意！合约的数额太大了！",
            },
            coinflip: {
              title: "选择硬币面下注",
            },
            dice: {
              title: "选择要下注的骰子号",
            },
            two_dice: {
              title: "选择要下注的骰子总数",
            },
            trustroll: {
              title: "调整你的中奖机会",
            },
            roulette: {
              title: "调整你的中奖机会",
            },
            won: "你赢了!",
            lost: "你输了!",
            do_not_give_up:
              "不要轻易放弃！也许你就要中头彩了。",
            jackpot_roll_was: "奖金卷是",
            bet_submitted: "等待BNB的到来",
            waiting_for_bnb: "等待BNB的到来...",
          },
          button: {
            play: "立即开玩",
          },
          outputwin: {
            winning_chance: "無料のBNBのテストネット",
            winning_bet: "投注中奖支付",
            you_will_win: "你会赢",
            fee: "的手续费",
            jackpot_contains: "头奖包含",
            lucky_number: "幸运数字是……",
          },
          errors: {
            settlebet: "投注结算失败",
            connect_support: "联系客服支持",
          },
          history: {
            all_bets: "联系客服支持",
            all: "联系客服支持",
            my_bets: "我的投注",
            player: "我的投注",
            bet: "下注",
            result: "投注结果",
            jackpot: "頭獎",
            not_available_data: "无可用数据",
            bottom: {
              address: "address",
              bet: "bet",
              bet_on: "投注于",
              bet_trx: "bet trx",
              aha3_secret: "aha3 (secret)",
              secret: "secret",
              aha3_bet_block: "aha3(bet block)",
              aha3_blk_secret: "aha3(blk + secret)",
              aha3_mod: "aha3 mod",
              jackpot: "jackpot",
              wins: "wins",
            },
          },
          docs: "https://docs.trustroll.win/trustroll/",
        },
      },
      ru: {
        translation: {
          // here we will place our translations...
          title: {
            maintitle: "Тебе повезет",
          },
          description: {
            maindescription:
              "Наслаждайтесь нашими децентрализованными играми в кости на основе смарт-контракта! Играйте честно и берегите себя!",
          },
          statistics: {
            wagers: "Ставки за 24 часа",
            bets: "ставок",
            recentjackpot: "Последний джекпот",
            wonby: "Выиграл",
            topwinner: "Топ победителей за 24ч",
          },
          menu: {
            coinflip: "Coinflip",
            dice: "Dice",
            twodice: "Twodice",
            trustroll: "Trustroll",
            docs: "Docs",
            net: "Выбрать сеть",
            connectwallet: "подключить кошелек",
            wrong_network: "неверная сеть!",
          },
          gametext: {
            coinflip: "Шансы на выигрыш 50/50, выплаты 1,98х. Испытай удачу!",
            dice: "Ставки на номера сторон от 1 до 6, выплаты до 5,82х. Делайте ставки и выигрывайте!",
            twodice:
              "Ставки на сумму чисел от 2 до 12, выплаты до 35,64х. Хватит ждать — начинай играть!",
            trustroll:
              "Любые шансы на выигрыш, от 1 до 98%, выплаты до 99х — все зависит от вас!",
            play: "Играй сейчас",
          },
          footer: {
            ourgames: "Наши игры",
            coinflip: "Coinflip",
            dice: "Dice",
            twodice: "Twodice",
            trustroll: "Trustroll",
            roulette: "Roulette",
            blockchainfriends: "Блокчейн и друзья",
            yourbalance: "Ваш баланс",
            walletisnoconnected: "кошелек не подключен",
            network: "Сеть",
            smartcontract: "Smart contract",
            free_bnb_testnet: "Free BNB тестовая сеть",
            featured_on: "Нас рекомендуют",
            reach_out_to_us: "Свяжитесь с нами",
            docs: "Docs",
          },
          game: {
            yourbet: "Ваша ставка",
            warning: {
              minbet: "Минимальная ставка 0.01 BNB!",
              yourwallet: "На Вашем кошельке мало валюты!",
              too_big_sum: "Внимание! Слишком большая сумма для контракта!",
            },
            coinflip: {
              title: "Выберите сторону медали для ставки",
            },
            dice: {
              title: "Выберите сторону кубика для ставки",
            },
            two_dice: {
              title: "Выберите сумму кубиков для ставки",
            },
            trustroll: {
              title: "Расчитайте свой шанс выигрыша",
            },
            roulette: {
              title: "Adjust your win chance",
            },
            won: "Вы выиграли!",
            lost: "Вы проиграли!",
            do_not_give_up: "Не сдавайся! Может быть, твой джекпот уже близко?",
            jackpot_roll_was: "Джекпот был",
            bet_submitted: "Ставка подтверждена!",
            waiting_for_bnb: "Ожидаем BNB...",
          },
          button: {
            play: "Играть",
          },
          outputwin: {
            winning_chance: "Шанс на победу",
            winning_bet: "Выигрышная ставка оплачивается",
            you_will_win: "Вы выиграете",
            fee: "комиссия",
            jackpot_contains: "Джекпот составляет",
            lucky_number: "Счастливый номер",
          },
          errors: {
            settlebet: "Ставка не прошла",
            connect_support: "Связаться с техподдержкой",
          },
          history: {
            all: "Все",
            all_bets: "Все ставки",
            my_bets: "Мои ставки",
            player: "Игрок",
            bet: "Ставка",
            result: "Результат",
            jackpot: "Джекпот",
            not_available_data: "Нет доступных данных",
            bottom: {
              address: "address",
              bet: "bet",
              bet_on: "bet on",
              bet_trx: "bet trx",
              aha3_secret: "aha3 (secret)",
              secret: "secret",
              aha3_bet_block: "aha3(bet block)",
              aha3_blk_secret: "aha3(blk + secret)",
              aha3_mod: "aha3 mod",
              jackpot: "jackpot",
              wins: "wins",
            },
          },
          docs: "https://docs.trustroll.win/trustroll/",
        },
      },
    },
  });

export default i18n;
