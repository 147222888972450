import React, { useState, useContext, useEffect } from "react";
import constants from "../../constance"
import BlockchainContext from "../../BlockchainContext";
import Identicon from 'react-identicons';
import wrongNetwork from '../../assets/img/wrong-network.svg';
import Web3 from "web3";
import { useTranslation, Trans } from 'react-i18next';

export default function ButtonTransparent({ text, href }) {

    const { t } = useTranslation(); // multilang

    const blockchainContext = useContext(BlockchainContext)
    const { handleNetworkSwitch, openModal, gamblerBalance, CONTRACT_ADDRESS, accounts, incorrectNetwork, walletConnectedFlag, balance } = blockchainContext
    const [newGamblerBalance, setGamblerBalance] = useState(balance)
    let contractAddress = '';
    let wallet = null;
    const {
        NET
        //     mainOrTestVersion,
        //     SITE_MAIN_LINK,
    } = constants;


    const CHAIN_ID = NET.BLOCKCHAIN.CHAIN_ID;
    // let text = "Play on ";
    // (mainOrTestVersion)? text += "testnet" : text += "mainnet" ;
    try {

        if (walletConnectedFlag && accounts.length !== 0) {
            wallet = accounts[0];
            // console.log({accounts});
            const contractAddressFirstHalf = wallet.slice(0, 6);
            const contractAddressSecondHalf = wallet.slice(38);
            contractAddress = [[...contractAddressFirstHalf].join(""), "...", [...contractAddressSecondHalf].join("")].join("");
            // console.log({contractAddressFirstHalf});
        }
    } catch (e) { console.log(e) }

    useEffect(() => {
        setGamblerBalance(balance);
    }, [walletConnectedFlag, incorrectNetwork, balance])


    return (
        <>

            {/* {(Web3.givenProvider.chainId === "0x" + CHAIN_ID.toString(16) ? <div>326462</div> :<div>Wrong network</div>)} */}
            <div className={walletConnectedFlag ? "activeBtn button-transparent" : (walletConnectedFlag == false && incorrectNetwork == true) ? "errorBtn button-transparent" : walletConnectedFlag == false ? "button-transparent" : "button-transparent"}
                // onClick={handleNetworkSwitch}>
                onClick={openModal}>
                <div className='button-transparent-text'>{
                    walletConnectedFlag ?
                        <div className="button-flex">{`${((Number(balance)) / (10 ** 18)).toFixed(3)} BNB `}<div className="myContractAddress">
                            {contractAddress}

                            <div className="ava">
                                <Identicon string={wallet} fg="transparent" bg="#ffb347" size="16" /></div>
                        </div></div> : (walletConnectedFlag == false && incorrectNetwork == true) ?
                            <div className="wrong-network-block"><img src={wrongNetwork} alt="Error icon." /> <span><Trans i18nKey="menu.wrong_network">wrong network!</Trans></span></div> :
                            text}
                </div>
            </div>
        </>

    )
}
